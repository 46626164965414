<template>
  <Layout class="container">
    <article class="devsite-article" has-bookmark="">
      <div class="devsite-article-meta nocontent" role="navigation">
        <ul class="devsite-breadcrumb-list"></ul>
      </div>
      <h1 class="devsite-page-title">Google API Services User Data Policy</h1>
      <div class="devsite-article-body clearfix mb-5">

        <p><em>Last updated August 22, 2022</em></p>

        <p>Google API Services, including Google Sign-In, are part of an authentication and
          authorization framework that gives you, the developer, the ability to connect
          directly with Google users when you would like to request access to Google user
          data. The policy below, as well as the <a href="/terms">Google APIs Terms of Service</a>,
          govern the use of Google API Services when you request access to Google user
          data. Please check back from time to time as these policies are occasionally
          updated.</p>

        <h2 id="accurately_represent_your_identity_and_intent" data-text="Accurately represent your identity and intent"
            role="presentation"><span class="devsite-heading" role="heading" aria-level="2">Accurately represent your identity and intent</span>
        </h2>

        <p>If you wish to access Google user data you must provide Google users and Google
          with clear and accurate information regarding your use of Google API Services.
          This includes, without limitation, requirements to accurately represent:</p>

        <ul>
          <li><strong>Who is requesting Google user data?</strong> All permission requests must
            accurately represent the identity of the application that seeks access to user
            data. If you have obtained authorized client credentials to access Google API
            Services, keep these credentials confidential.
          </li>
          <li><strong>What data are you requesting?</strong> You must provide clear and accurate
            information explaining the types of data being requested. In addition, if you
            plan to access or use a type of user data that was not originally disclosed in
            your privacy policy (or in-product disclosures) when a Google user initially
            authorized access, you must update your privacy policy and prompt the user to
            consent to any changes before you may access that data.
          </li>
          <li><strong>Why are you requesting Google user data?</strong> Be honest and transparent with
            users when you explain the purpose for which your application requests user
            data. If your application requests data for one reason but the data will also
            be utilized for a secondary purpose, you must notify Google users of both use
            cases. As a general matter, users should be able to readily understand the
            value of providing the data that your application requests, as well as the
            consequences of sharing that data with your application.
          </li>
        </ul>

        <h2 id="be_transparent_about_the_data_you_access_with_clear_and_prominent_privacy_disclosures"
            data-text="Be transparent about the data you access with clear and prominent privacy disclosures">Be
          transparent about the data you access with clear and prominent privacy disclosures</h2>

        <p>You must publish a privacy policy that fully documents how your application
          interacts with user data. You must list the privacy policy URL in your OAuth
          client configuration when your application is made available to the public.</p>

        <p><strong><u>Your Privacy Policy and all in-product privacy notifications should be
          accurate, comprehensive, and easily accessible</u>.</strong> Your privacy policy and
          in-product privacy notifications must thoroughly disclose the manner in which
          your application accesses, uses, stores, or shares Google user data. Your use of
          Google user data must be limited to the practices explicitly disclosed in your
          published privacy policy, but you should consider the use of additional in-
          product notifications to ensure that users understand how your application will
          handle user data. If you change the way your application uses Google user data,
          you must notify users and prompt them to consent to an updated privacy policy
          before you make use of Google user data in a new way or for a different purpose
          than originally disclosed.</p>

        <p><strong><u>Disclosures about data use should be prominent and timely</u>.</strong> Your
          privacy policy and any in-product notifications regarding data use should be
          prominently displayed in your application interface so that users can find this
          information easily. Where possible, disclosures about data use should be timely
          and shown in context.</p>

        <h2 id="request_the_minimum_relevant_permissions" data-text="Request the minimum relevant permissions">Request
          the minimum relevant permissions</h2>

        <p>Permission requests should make sense to users, and should be limited to the
          critical information necessary to implement your application.</p>

        <p><strong><u>Don't request access to information that you don't need</u>.</strong> Only request
          access to the permissions necessary to implement your application's features or
          services. If your application does not require access to specific permissions,
          then you must not request access to these permissions. Don't attempt to "future
          proof" your access to user data by requesting access to information that might
          benefit services or features that have not yet been implemented.</p>

        <p><strong><u>Request permissions in context where possible</u>.</strong> Only request access to
          user data in context (via incremental auth) whenever you can, so that users
          understand why you need the data.</p>

        <h2 id="deceptive_or_unauthorized_use_of_google_api_services_is_prohibited"
            data-text="Deceptive or unauthorized use of Google API Services is prohibited">Deceptive or unauthorized use
          of Google API Services is prohibited</h2>

        <p>You are strictly prohibited from engaging in any activity that may deceive users
          or Google about your use of Google API Services. This includes without
          limitation the following requirements:</p>

        <p><strong><u>Do not misrepresent what data is collected or what you do with Google user
          data</u>.</strong> Be up front with users so that they can make an informed decision to
          grant authorization. You must disclose all user data that you access, use,
          store, delete, or share, as well as any actions you take on a user's behalf.</p>

        <p>You are not permitted to access, aggregate, or analyze Google user data if the
          data will be displayed, sold, or otherwise distributed to a third party
          conducting surveillance.</p>

        <p>Overall there should be no surprises for Google users: hidden features,
          services, or actions that are inconsistent with the marketed purpose of your
          application may lead Google to suspend your ability to access Google API
          Services.</p>

        <p><strong><u>Do not mislead Google about an application's operating environment</u>.</strong>
          You must accurately represent the environment in which the authentication page
          appears. For example, don't claim to be an Android application in the user agent
          header if your application is running on iOS, or represent that your
          application's authentication page is rendered in a desktop browser if instead
          the authentication page is rendered in an embedded web view.</p>

        <p><strong><u>Do not use undocumented APIs without express permission</u>.</strong> Don't
          reverse engineer undocumented Google API Services or otherwise attempt to derive
          or use the underlying source code of undocumented Google API Services. You may
          only access data from Google API Services according to the means stipulated in
          the official documentation of that API Service, as provided on Google's
          <a href="https://developers.google.com/">Developer Page</a>.</p>

        <p><strong><u>Do not make false or misleading statements about any entities that have
          allegedly authorized or managed your application</u>.</strong> You must accurately
          represent the company, organization, or other authority that manages your
          application. Making false representations about client credentials to Google or
          Google users is grounds for suspension.</p>

        <h2 id="child-directed_apps" data-text="Child-directed apps">Child-directed apps</h2>

        <p>The Children's Online Privacy Protection Act, or <a
            href="https://www.ftc.gov/tips-advice/business-center/privacy-and-security/children%27s-privacy">COPPA</a>,
          applies to
          websites, apps, and services directed to children under the age of 13 and
          general audience apps, websites, or services with users known to be under the
          age of 13. While <a href="https://play.google.com/about/families/coppa-compliance/child-directed/">child-directed
            apps may use some Google services</a>,
          developers are responsible for using these services according to their
          obligations under the law. Please review the FTC's guidance on COPPA (including
          information about the differences between mixed audience apps and apps directed
          primarily to children from the <a
              href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions">FTC's
            website</a>) and consult with your
          own legal counsel.</p>

        <p><strong><u>Child-directed apps</u>:</strong> If your application is directed primarily at
          children, it should not use Google Sign-In or any other Google API Service that
          accesses data associated with a Google Account. This restriction includes Google
          Play Games Services and any other Google API Service using the OAuth technology
          for authentication and authorization.</p>

        <p><strong><u>Mixed audience apps</u>:</strong> Applications that are mixed audience shouldn't
          require users to sign in to a Google Account, but can offer, for example, Google
          Sign-In or Google Play Games Services as an optional feature. In these cases,
          users must be able to access the application in its entirety without signing
          into a Google Account.</p>

        <h2 id="maintain_a_secure_operating_environment" data-text="Maintain a secure operating environment">Maintain a
          secure operating environment</h2>

        <p>We expect all user data is secure in transit and at rest. Take reasonable and
          appropriate steps to protect all applications or systems that make use of Google
          API Service and any data derived from it against unauthorized or unlawful
          access, use, destruction, loss, alteration, or disclosure.</p>

        <h2 id="additional_requirements_for_specific_api_scopes"
            data-text="Additional Requirements for Specific API Scopes">Additional Requirements for Specific API
          Scopes</h2>

        <p>Certain Google OAuth API Scopes (the "<a
            href="https://support.google.com/cloud/answer/9110914#sensitive-scopes">Sensitive</a> and
          <a href="https://support.google.com/cloud/answer/9110914#restricted-scopes">Restricted</a> Scopes") are
          subject to additional requirements
          that can be found in each product's User Data and Developer Policy or the
          <a href="https://developers.google.com/">Google Developer Page</a>. More information about the requirements to
          obtain
          (or keep) access to these scopes is also available in the
          <a href="https://support.google.com/cloud/answer/9110914#restricted-scopes">OAuth Application Verification
            FAQ</a>.</p>

        <blockquote>
          <p><strong>Note:</strong> If your app is only used by users within your own domain, then these
            requirements do not apply. <a href="https://support.google.com/a/topic/29157?ref_topic=7570177">Google
              Workspace</a> can control access to
            connected applications
            <a href="https://support.google.com/a/answer/7281227">via allowlisting</a>. Learn more
            about <a
                href="https://cloud.google.com/blog/products/g-suite/take-charge-your-oauth-ecosystem-these-best-practices">best
              practices</a> for managing your enterprise OAuth
            ecosystem.</p>
        </blockquote>

        <p><strong><u>Additional requirements include:</u></strong></p>

        <ol>
          <li><p><strong><u>Appropriate Access:</u></strong> Developers may only request access to the
            scopes for a permitted Application Type described by the product. Such
            application types can be found under an Appropriate Access heading in the
            product specific policy or the product's <a href="https://developers.google.com/">Google Developer Page</a>.
          </p></li>
          <li><p id="limited-use"><strong><u>Limited Use:</u></strong> Your use of data obtained via the product's
            specified scopes must comply with the below requirements. These requirements
            apply to the raw data obtained from the scopes and data aggregated,
            anonymized, or derived from them. </p>

            <ol>
              <li><p>Limit your use of data to providing or improving user-facing features
                that are prominent in the requesting application's user interface;</p></li>
              <li><p>Transfers of data are not allowed, except:</p>

                <ol>
                  <li>To provide or improve your appropriate access or user-facing
                    features that are visible and prominent in the requesting
                    application's user interface and only with the user's consent;
                  </li>
                  <li>For security purposes (for example, investigating abuse);</li>
                  <li>To comply with applicable laws; or,</li>
                  <li>As part of a merger, acquisition, or sale of assets of the developer
                    after obtaining explicit prior consent from the user.
                  </li>
                </ol>
              </li>
              <li><p>Don't allow humans to read the data, unless:</p>

                <ol>
                  <li>You first obtained the user's affirmative agreement to view specific
                    messages, files, or other data, with the limited exception of use
                    cases approved by Google under additional terms applicable to the
                    Nest Device Access program;
                  </li>
                  <li>It is necessary for security purposes (for example, investigating a
                    bug or abuse);
                  </li>
                  <li>It is necessary to comply with applicable law; or</li>
                  <li>The data (including derivations) is aggregated and used for internal
                    operations in accordance with applicable privacy and other
                    jurisdictional legal requirements.
                  </li>
                </ol>
              </li>
            </ol>

            <p>All other transfers, uses, or sales of user data are prohibited, including:</p>

            <ol>
              <li>Transferring or selling user data to third parties like advertising
                platforms, data brokers, or any information resellers.
              </li>
              <li>Transferring, selling, or using user data for serving ads, including
                retargeting, personalized or interest-based advertising.
              </li>
              <li>Transferring, selling, or using user data to determine credit-worthiness
                or for lending purposes.
              </li>
            </ol>

            <p>You must ensure that your employees, agents, contractors, and successors
              comply with this Google API Services User Data Policy.</p></li>
          <li><p id="secure-data-handling"><strong><u>Secure Data Handling:</u></strong> Applications accessing the
            product
            specified scopes (the "<a
                href="https://support.google.com/cloud/answer/9110914#sensitive-scopes">Sensitive</a> and <a
                href="https://support.google.com/cloud/answer/9110914#restricted-scopes">Restricted</a> Scopes") must
            demonstrate that they adhere to certain
            security practices. Depending on the API being accessed and number of user
            grants or users, applications must pass an annual security assessment and
            obtain a Letter of Assessment from a Google-designated third party. More
            information about the assessment requirements to obtain or keep access to
            the scopes is also available in the <a
                href="https://support.google.com/cloud/answer/9110914#restricted-scopes">OAuth Application Verification
              FAQ</a> and the product's <a href="https://developers.google.com/">Google Developer Page</a>.
          </p></li>
        </ol>

        <h2 id="enforcement" data-text="Enforcement">Enforcement</h2>
        <p>You must access Google API Services in accordance with the <a href="/terms">Google APIs Terms of
          Service</a>. If you are found to be out of compliance with the <a href="/terms">Google APIs
          Terms of Service</a>, this Google API Services: User Data Policy, or any
          Google product policies that are applicable to the Google API Service you are
          using, Google may revoke or suspend your access to Google API Services and other
          Google products and services if you are found in violation of other product
          policies, terms of service, or other guidelines. Your access to Google API
          Services may also be revoked if your application enables end-users or other
          parties to violate the Google APIs Terms of Service and/or Google policies.
        </p>
      </div>
    </article>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main"

export default {
  name: "privacy-policy",
  components: {
    Layout
  }
}
</script>

<style scoped>
body[layout=docs] .devsite-article {
  background-color: #fff;
  padding: 40px;
}

[template=page] .devsite-article-body {
  margin-top: 16px;
}

.devsite-article-meta {
  display: grid;
  grid-gap: 0 16px;
  grid-template-areas: "breadcrumbs ratings";
  grid-template-columns: 1fr auto;
  margin: var(--devsite-article-meta-margin,0 0 16px);
}

ol ol {
  list-style-type: decimal;
}

ol ol ol {
  list-style-type: lower-alpha;
}
</style>